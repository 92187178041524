/* Base styles */
:root {
  --primary-color: #007bff;
  --background-color: #f0f2f5;
  --border-color: #ccc;
  --text-color: #333;
  --error-color: #dc3545;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family);
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* App container */
.app-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 16px !important;
  background: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* Utility classes */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Focus styles for accessibility */
:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* Disable text selection for buttons */
button {
  user-select: none;
}

/* Basic form element styling */
input, 
button {
  font-family: inherit;
  font-size: 1rem;
}

/* Animation keyframes for loading states */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.message-list {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}

.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}

.user-message {
  background-color: #007bff;
  color: white;
  margin-left: auto;
  max-width: 70%;
}

.assistant-message {
  background-color: #f1f1f1;
  max-width: 70%;
}

.input-form {
  display: flex;
  gap: 10px;
}

.message-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.send-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.send-button:disabled {
  background-color: #ccc;
}

.error {
  color: red;
  margin-top: 10px;
} 