/* Main Container */
.container {
  max-width: 800px;
  margin: 1rem auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px 20px 0 20px;
  border-radius: 24px !important;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  overflow: clip;
}

/* Header Styles */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px 20px;
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
}

.header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #343a40;
}

.authButton {
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.authButton:hover {
  background: #0056b3;
}

/* Three-Dot Button */
.threeDotButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 8px;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.2s ease;
}

.threeDotButton:hover {
  background: #f3f4f6;
  color: #2563eb;
}

/* Pop-Up Dropdown */
.popupDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  z-index: 1000;
  margin-top: 5px;
  min-width: 150px;
}

.popupDropdown li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.popupDropdown li:hover {
  background: #f1f1f1;
}

/* Auth Option */
.authOption {
  font-weight: bold;
  color: #007bff;
}

.authOption:hover {
  background: #e0f0ff;
}

/* User Info */
.userInfo {
  font-size: 0.9rem;
  color: #666;
  padding: 5px 20px;
  border-bottom: 1px solid #eee;
  cursor: default;
}

/* Message List */
.messageList {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background: #ffffff;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 80px;
}

/* Message Styles */
.messageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}

.userWrapper {
  align-items: flex-end;
}

.assistantWrapper {
  align-items: flex-start;
}

.message {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 0.95rem;
  line-height: 1.4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  position: relative;
}

.thinkingMessage {
  background: #f8f9fa !important;
  color: #6c757d !important;
  border-top-left-radius: 4px;
  font-style: italic;
  opacity: 0.9;
  border-left: 3px solid #6c757d;
}

.assistantMessage {
  background: #e9ecef;
  color: #343a40;
  border-top-left-radius: 4px;
}

.userMessage {
  background: #007bff;
  color: white;
  border-top-right-radius: 4px;
}

.errorMessage {
  background: #fee2e2;
  color: #991b1b;
  border-top-left-radius: 4px;
}

.paymentErrorMessage {
  background: #fff7ed;
  color: #9a3412;
  border-left: 3px solid #ea580c;
  font-weight: 500;
}

.paymentErrorMessage::before {
  content: '💰';
  margin-right: 6px;
}

/* Input Form */
.inputForm {
  display: flex;
  gap: 10px;
  padding: 15px 20px;
  margin-bottom: 20px;
  background: #f7f7f8;
  border-top: 1px solid #e5e5e5;
  border-radius: 6px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 800px;
  z-index: 100;
}

.input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  font-size: 0.95rem;
  min-height: 44px;
  max-height: 120px;
  overflow-y: auto;
  width: 0;
  min-width: 0;
  background: #ffffff;
  resize: none;
  line-height: 1.5;
  font-family: inherit;
  transition: height 0.1s ease-out;
  box-sizing: border-box;
}

.input:focus {
  outline: none;
  border-color: #ffffff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.input:disabled {
  background: #f8f9fa;
  cursor: not-allowed;
}

/* Buttons */
.button {
  padding: 12px 24px;
  min-width: 80px;
  height: 44px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.95rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.button:hover:not(:disabled) {
  background: #0056b3;
}

.button:disabled {
  background: #6c757d;
  cursor: not-allowed;
}

/* Code Block Styles */
.messageContent pre {
  margin: 1em 0;
  background: #f6f8fa;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.messageContent code {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
  margin: 0;
  background-color: rgba(175, 184, 193, 0.2);
  border-radius: 6px;
}

.messageContent pre code {
  background-color: transparent;
  padding: 0;
}

.codeBlock {
  margin: 0;
  padding: 16px;
  overflow-x: auto;
  max-height: 500px;
}

.codeBlock pre {
  margin: 0;
  padding: 0;
  background: transparent;
}

.codeBlock code {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
  font-size: 13px;
  line-height: 1.45;
}

.codeHeader {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #e1e4e8;
  border-bottom: 1px solid #d1d5da;
  z-index: 10;
}

.language {
  font-size: 12px;
  font-weight: 600;
  color: #24292e;
  text-transform: uppercase;
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #24292e;
  background: transparent;
  border: 1px solid #d1d5da;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.copyButton:hover {
  background: #f3f4f6;
  border-color: #b1b5ba;
}

/* Loading Animation */
.loadingDots {
  display: inline-block;
}

.loadingDots span {
  animation: dots 1.4s infinite;
  display: inline-block;
  margin: 0 2px;
  opacity: 0;
}

.loadingDots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dots {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

/* Custom Scrollbar */
.messageList::-webkit-scrollbar {
  width: 6px;
}

.messageList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.messageList::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.messageList::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Empty State */
.emptyState {
  text-align: center;
  color: #6c757d;
  margin-top: 40%;
  transform: translateY(-50%);
}

.emptyState p {
  margin: 5px 0;
}

.suggestion {
  font-size: 0.9rem;
  opacity: 0.7;
}

/* Media Queries */
@media (max-width: 768px) {
  .container {
    max-width: 100%;
    margin: 1;
    border-radius: 0;
    box-shadow: none;
  }

  .header {
    padding: 12px 16px;
  }

  .messageList {
    padding: 12px;
  }

  .inputForm {
    padding: 12px;
    max-width: 90%;
  }

  .message {
    max-width: 85%;
    padding: 10px 12px;
    font-size: 0.9rem;
  }

  .input {
    font-size: 16px;
    padding: 10px 12px;
  }

  .button {
    padding: 10px 16px;
    min-width: 60px;
  }
}

@media (max-width: 320px) {
  .message {
    max-width: 90%;
    font-size: 0.85rem;
  }

  .inputForm {
    padding: 8px;
  }

  .button {
    min-width: 50px;
    padding: 8px;
  }
}

/* Safe Area Support */
@supports (padding: max(0px)) {
  .container {
    padding-left: max(20px, env(safe-area-inset-left));
    padding-right: max(20px, env(safe-area-inset-right));
    padding-bottom: max(20px, env(safe-area-inset-bottom));
  }
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  .container {
    background: #1a1a1a;
  }

  .header {
    background: #1a1a1a;
    border-bottom-color: #333;
  }

  .header h2 {
    color: #e9ecef;
  }

  .messageList {
    background: #1a1a1a;
  }

  .inputForm {
    background: #242424;
  }

  .input {
    background: #333;
    color: #e9ecef;
  }

  .threeDotButton {
    color: #e9ecef;
  }

  .threeDotButton:hover {
    color: #66b0ff;
  }

  .popupDropdown {
    background: #242424;
    border-color: #333;
  }

  .popupDropdown li {
    color: #e9ecef;
  }

  .popupDropdown li:hover {
    background: #333;
  }

  .authOption {
    color: #66b0ff;
  }

  .authOption:hover {
    background: #1a3f66;
  }
  
  .userInfo {
    color: #aaa;
    border-bottom-color: #333;
  }
}

.headerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 8px;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.2s ease;
}

.headerButton:hover {
  background: #f3f4f6;
  color: #2563eb;
}

/* Session List */
.sessionList {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sessionHeader {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.sessionHeader h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.sessionItems {
  flex: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.sessionItem {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sessionItem:hover {
  background-color: #f5f5f5;
}

.sessionTitle {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 4px;
}

.sessionDate {
  font-size: 0.8rem;
  color: #666;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  .sessionHeader {
    border-bottom-color: #333;
  }

  .sessionHeader h2 {
    color: #e9ecef;
  }

  .sessionItem {
    border-bottom-color: #333;
  }

  .sessionItem:hover {
    background-color: #2a2a2a;
  }

  .sessionTitle {
    color: #e9ecef;
  }

  .sessionDate {
    color: #999;
  }
}

/* Left Buttons Container */
.leftButtons {
  position: relative;  /* This creates positioning context for dropdown */
  display: flex;
  gap: 8px;
  z-index: 1001;      /* Ensure dropdown appears above other elements */
}

/* Target ReactMarkdown container if needed */
.messageContent > div > pre {
  margin: 0.5rem 0;
  padding: 0;
}

.messageContent p {
  margin: 0.5rem 0;
}

.messageContent h1,
.messageContent h2,
.messageContent h3,
.messageContent h4,
.messageContent h5,
.messageContent h6,
.messageContent ul,
.messageContent ol {
  margin: 0.5rem 0;
}

.chat-container {
  overflow-y: auto;
  /* Remove any scroll-behavior: smooth if present */
}

.codeWrapper {
  margin: 0;
  padding: 0;
}

.llmSelector {
  padding: 6px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  background: #fff;
  color: #333;
  font-size: 0.9rem;
  cursor: pointer;
  margin-right: 10px;
}

.llmSelector:focus {
  outline: none;
  border-color: #007bff;
}

@media (prefers-color-scheme: dark) {
  .llmSelector {
    background: #333;
    color: #e9ecef;
    border-color: #444;
  }
}

.stopButton {
  background: #dc3545;
}

.stopButton:hover:not(:disabled) {
  background: #bb2d3b;
}

.stopButton:active:not(:disabled) {
  background: #a52834;
}

.messageHeader {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

.message:hover .messageHeader {
  opacity: 1;
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #24292e;
  background: transparent;
  border: 1px solid #d1d5da;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.copyButton:hover {
  background: #f3f4f6;
  border-color: #b1b5ba;
}

@media (prefers-color-scheme: dark) {
  .copyButton {
    color: #e9ecef;
    border-color: #444;
  }
  
  .copyButton:hover {
    background: #333;
    border-color: #666;
  }
}

.promptButton {
  padding: 12px 24px;
  min-width: 44px;
  height: 44px;
  background: #6c757d;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.95rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 8px;
}

.promptButton:hover:not(:disabled) {
  background: #5a6268;
}

.promptOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.promptModal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.promptModal h2 {
  margin-bottom: 15px;
  color: #343a40;
}

.promptTextarea {
  width: 100%;
  min-height: 150px;
  padding: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 15px;
  font-family: inherit;
  font-size: 0.95rem;
  resize: vertical;
}

.promptButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

@media (prefers-color-scheme: dark) {
  .promptModal {
    background: #1a1a1a;
  }
  
  .promptModal h2 {
    color: #e9ecef;
  }
  
  .promptTextarea {
    background: #333;
    color: #e9ecef;
    border-color: #444;
  }
}

.modelLabel {
  padding: 8px 20px;
  color: #666;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  background: #f5f5f5;
}

.activeOption {
  background: #e0f0ff;
  color: #007bff;
  font-weight: 500;
}

.dividerLine {
  height: 1px;
  background: #e5e5e5;
  margin: 8px 0;
}

.popupDropdown li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.2s;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .modelLabel {
    background: #333;
    color: #999;
  }

  .activeOption {
    background: #1a3f66;
    color: #66b0ff;
  }

  .dividerLine {
    background: #444;
  }
}

.rootContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
}

/* Base styles for sliding panel */
.slidingPanel {
  position: absolute;
  height: 100%;
  background: #ffffff;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  transform: translateX(0);
}

.slidingPanel.open {
  transform: translateX(100%);
}

/* Mobile styles (default) */
@media screen and (max-width: 767px) {
  .slidingPanel {
    left: -80%;
    width: 80%;
  }

  .mainContent.shifted {
    transform: translateX(80%);
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) {
  .slidingPanel {
    left: -30%;
    width: 30%;
  }

  .mainContent.shifted {
    transform: translateX(30%);
  }
}

.mainContent {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.sessionList {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.sessionList h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5rem;
}

.sessionItem {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sessionItem:hover {
  background-color: #f5f5f5;
}

@media (prefers-color-scheme: dark) {
  .slidingPanel {
    background: #1a1a1a;
  }
  
  .sessionItem {
    border-bottom-color: #333;
  }
  
  .sessionItem:hover {
    background-color: #2a2a2a;
  }
}

.mainContent::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 0.3s ease-in-out;
  pointer-events: none;
  z-index: 999;
}

.mainContent.shifted::before {
  background: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
}

@media (prefers-color-scheme: dark) {
  .mainContent.shifted::before {
    background: rgba(0, 0, 0, 0.7);
  }
}

.messageContent {
  padding-left: 24px;  /* Add padding for lists */
}

.messageContent ul,
.messageContent ol {
  margin: 0.5rem 0;
  padding-left: 1rem;  /* Reduce default list padding */
}

.messageContent li {
  margin-left: -24px;  /* Compensate for the messageContent padding */
}