.sessionList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

.header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.closeButton {
  border: none;
  background: none;
  font-size: 22px;
  cursor: pointer;
  color: #666;
  padding: 0;
  line-height: 1;
}

.closeButton:hover {
  color: #000;
}

.sessionItems {
  flex: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.sessionItem {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sessionItem:hover {
  background-color: #e9e9e9;
}

.sessionItem.active {
  background-color: #e0e0e0;
}

.sessionInfo {
  flex: 1;
  min-width: 0;
}

.sessionTitle {
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sessionMeta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #777;
}

.sessionDate,
.sessionModel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadingContainer,
.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  color: #666;
  text-align: center;
}

.spinner {
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
  font-size: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  .header,
  .sessionItem {
    background-color: #1e1e1e;
    border-color: #333;
  }
  
  .header h3,
  .sessionTitle {
    color: #fff;
  }
  
  .sessionMeta {
    color: #aaa;
  }
  
  .sessionItem:hover {
    background-color: #2a2a2a;
  }
  
  .sessionItem.active {
    background-color: #333;
  }
  
  .closeButton {
    color: #aaa;
  }
  
  .closeButton:hover {
    color: #fff;
  }
  
  .loadingContainer,
  .emptyState {
    color: #aaa;
  }
}

.errorState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  color: #e53e3e;
  text-align: center;
}

.retryButton {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.retryButton:hover {
  background-color: #2c5282;
}

.deleteButton {
  background: none;
  border: none;
  color: #888;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  opacity: 0.5;
  transition: all 0.2s ease;
}

.deleteButton:hover {
  background-color: rgba(239, 68, 68, 0.1);
  color: #ef4444;
  opacity: 1;
}

.sessionItem:hover .deleteButton {
  opacity: 0.8;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  .deleteButton {
    color: #aaa;
  }
  
  .deleteButton:hover {
    background-color: rgba(239, 68, 68, 0.2);
    color: #f87171;
  }
} 