.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

/* Style for when auth modal is the main login screen */
.mainScreen {
    background-color: #f8fafc; /* Light background instead of semi-transparent */
    backdrop-filter: none;
}

.modalContent {
    background: white;
    padding: 2.5rem;
    border-radius: 16px;
    width: 100%;
    max-width: 440px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideIn 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

/* For main login screen, add more emphasis to the modal */
.mainScreen .modalContent {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
    max-width: 480px; /* Slightly larger */
}

/* Header styles */
.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.modalHeader img {
    height: 32px;
}

.closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    color: #666;
    transition: color 0.2s;
}

.closeButton:hover {
    color: #333;
}

/* Auth tabs */
.authTabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.tabButton {
    flex: 1;
    padding: 0.75rem;
    border: none;
    background: none;
    font-size: 1rem;
    color: #666;
    border-bottom: 2px solid transparent;
    transition: all 0.2s;
}

.tabButton.active {
    color: #2563eb;
    border-bottom-color: #2563eb;
    font-weight: 500;
}

/* Form elements */
.input {
    width: 100%;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    border: 1.5px solid #e5e7eb;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.submitButton {
    width: 100%;
    padding: 0.875rem;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submitButton:hover {
    background: #1d4ed8;
}

/* Social buttons */
.socialButton {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1.5px solid #e5e7eb;
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.socialButton:hover {
    background: #f9fafb;
}

/* Divider */
.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1.5rem 0;
    color: #666;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e5e7eb;
}

.divider span {
    padding: 0 1rem;
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Footer */
.modalFooter {
    margin-top: 1.5rem;
    text-align: center;
    color: #666;
    font-size: 0.875rem;
}

.modalFooter a {
    color: #2563eb;
    text-decoration: none;
    margin: 0 0.25rem;
}

.modalFooter a:hover {
    text-decoration: underline;
}

.logo {
    height: 32px;
    width: 32px;
    object-fit: contain;
}

.formFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-size: 0.875rem;
}

.checkbox {
    width: 16px;
    height: 16px;
    accent-color: #2563eb;
}

.forgotPassword {
    color: #2563eb;
    text-decoration: none;
    font-size: 0.875rem;
    transition: color 0.2s;
}

.forgotPassword:hover {
    color: #1d4ed8;
    text-decoration: underline;
}

.error {
    color: #ff0000;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    text-align: center;
}

/* Form elements */
.input {
    width: 100%;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    border: 1.5px solid #e5e7eb;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.submitButton {
    width: 100%;
    padding: 0.875rem;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submitButton:hover {
    background: #1d4ed8;
}

/* Social buttons */
.socialButton {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1.5px solid #e5e7eb;
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.socialButton:hover {
    background: #f9fafb;
}

/* Divider */
.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1.5rem 0;
    color: #666;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e5e7eb;
}

.divider span {
    padding: 0 1rem;
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Footer */
.modalFooter {
    margin-top: 1.5rem;
    text-align: center;
    color: #666;
    font-size: 0.875rem;
}

.modalFooter a {
    color: #2563eb;
    text-decoration: none;
    margin: 0 0.25rem;
}

.modalFooter a:hover {
    text-decoration: underline;
}

.logo {
    height: 32px;
    width: 32px;
    object-fit: contain;
}

.formFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-size: 0.875rem;
}

.checkbox {
    width: 16px;
    height: 16px;
    accent-color: #2563eb;
}

.forgotPassword {
    color: #2563eb;
    text-decoration: none;
    font-size: 0.875rem;
    transition: color 0.2s;
}

.forgotPassword:hover {
    color: #1d4ed8;
    text-decoration: underline;
} 